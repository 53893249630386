<template>
  <el-container>
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      {{pageTitle}}
      <template>
        <span v-if="listItemActive==='/member-info'" class="icon el-icon-edit-outline" @click="editVip">编辑</span>
        <span v-else-if="listItemActive==='/about-history'" class="icon el-icon-plus" @click="addAbout">添加预约</span>
        <span v-else></span>
      </template>
    </el-header>
    <el-container>
      <el-main class="left-main">
        <div class="body">
          <div class="header">
            <div class="avatar">
              <img :src="$getimgsrc(vipuser.avatar)" alt="">
            </div>
            <div class="name">{{  vipuser.realname}}</div>
          </div>
          <div class="list">
            <div :class="listItemActive==='/member-info'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/member-info','会员信息')">
              <img v-if="listItemActive==='/member-info'" src="@/assets/images/vip-info/1.1.png" alt="">
              <img v-else src="@/assets/images/vip-info/1.png" alt="">
              会员信息
            </div>
            <div :class="listItemActive==='/meal-card'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/meal-card','套餐次卡')">
              <img v-if="listItemActive==='/meal-card'" src="@/assets/images/vip-info/2.2.png" alt="">
              <img v-else src="@/assets/images/vip-info/2.png" alt="">
              套餐次卡
            </div>
            <div :class="listItemActive==='/coupon-card'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/coupon-card','优惠卡券')">
              <img v-if="listItemActive==='/coupon-card'" src="@/assets/images/vip-info/3.3.png" alt="">
              <img v-else src="@/assets/images/vip-info/3.png" alt="">
              优惠卡券
            </div>
            <!-- <div :class="listItemActive==='/check-goods'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/check-goods','寄存商品')">
              <img v-if="listItemActive==='/check-goods'" src="@/assets/images/vip-info/4.4.png" alt="">
              <img v-else src="@/assets/images/vip-info/4.png" alt="">
              寄存商品
            </div> -->
            <div :class="listItemActive==='/about-history'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/about-history','预约记录')">
              <img v-if="listItemActive==='/about-history'" src="@/assets/images/vip-info/5.5.png" alt="">
              <img v-else src="@/assets/images/vip-info/5.png" alt="">
              预约记录
            </div>
            <div :class="listItemActive==='/top-up-history'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/top-up-history','充值记录')">
              <img v-if="listItemActive==='/top-up-history'" src="@/assets/images/vip-info/6.6.png" alt="">
              <img v-else src="@/assets/images/vip-info/6.png" alt="">
              充值记录
            </div>
            <div :class="listItemActive==='/cost-history'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/cost-history','消费记录')">
              <img v-if="listItemActive==='/cost-history'" src="@/assets/images/vip-info/7.7.png" alt="">
              <img v-else src="@/assets/images/vip-info/7.png" alt="">
              消费记录
            </div>
            <div :class="listItemActive==='/integral-history'?'list-item list-item1':'list-item'"
              @click="jumpItemPath('/integral-history','积分记录')">
              <img v-if="listItemActive==='/integral-history'" src="@/assets/images/vip-info/8.8.png" alt="">
              <img v-else src="@/assets/images/vip-info/8.png" alt="">
              积分记录
            </div>

          </div>

          <div class="btm-btn">
            <div>
              <el-button style="width:120px; margin: 40px 10px 0;" type="danger" icon="el-icon-wallet" round
                @click="jumpItemPath('/cash-top-up','现金充值')">现金充值
              </el-button>
            </div>
            <div>
              <el-button style="width:120px; margin:10px 10px 40px; " type="primary" icon="el-icon-coin" round
                @click="jumpIntegralFor">积分换购
              </el-button>
            </div>
          </div>
        </div>
      </el-main>
      <el-main class="right-main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        listItemActive: '',
        pageTitle: '会员信息',
        returnPage: ''
      }
    },
    computed:{
      ...mapState(['vipuser'])
    },
    mounted() {
      this.returnPage = window.sessionStorage.getItem('returnPage')
      this.pageTitle = window.sessionStorage.getItem('pageTitle')
      this.listItemActive = window.sessionStorage.getItem('listItemActive')
      if (this.$route.path !== this.listItemActive) {
        this.$router.push({
          path: this.listItemActive
        })
      }
    },
    destroyed() {
      window.sessionStorage.setItem('pageTitle', '会员信息')
      window.sessionStorage.setItem('listItemActive', '/member-info')
    },
    methods: {
      // 返回上级
      returnOn() {
        if (this.returnPage) {
          this.$router.push({
            path: this.returnPage
          })
        } else {
          this.$router.push({
            path: '/work'
          })
        }


      },
      // 编辑会员
      editVip() {
        this.$router.push({
          name: 'EditVip'
        })
      },
      // 添加预约
      addAbout() {
        this.$router.push({
          name: 'AddReservation'
        })
      },
      // item 切换
      jumpItemPath(path, name) {
        if (this.listItemActive === path) return
        this.$router.push({
          path,
        })
        this.pageTitle = name
        this.listItemActive = path
        window.sessionStorage.setItem('listItemActive', path)
        window.sessionStorage.setItem('pageTitle', this.pageTitle)
      },
      jumpIntegralFor() {
        this.$router.push({
          name: 'IntegralFor'
        })
      },

    }
  };

</script>
<style lang="less" scoped>
  /deep/ .el-container {
    height: calc(100vh);
    background-color: #f9f9f9;

  }

  // 头部
  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    border-bottom: 1px solid #e9e9e9;

    .left {
      color: #46a6ff;
      font-size: 24px;
    }

    .icon {
      padding-right: 40px;
      color: #46a6ff;
      font-size: 14px;

      &::before {
        margin-right: 5px;
      }
    }
  }

  /deep/ .left-main.el-main {
    width: 25%;
    min-width: 240px;
    padding: 20px 10px 20px 20px !important;
    height: calc(100vh - 50px);

    .body {
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 0 4px #ccc;
      text-align: center;
      color: #666;
      overflow: hidden;


      .header {
        padding: 70px 0;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 20px;

        .avatar {
          margin-bottom: 15px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 100%;
          }
        }

      }

      .list {
        .list-item {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          border-right: 4px solid transparent;


          img {
            width: 18px;
            margin-right: 10px;
          }
        }

        .list-item1 {
          background-color: #ecf5ff;
          color: #409eff;
          border-right: 4px solid #409eff;
        }

        .btm-btn {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
  }

  /deep/ .right-main.el-main {
    width: 75%;
    height: calc(100vh - 50px);
    padding: 20px 20px 20px 10px !important;
  }

</style>
